<div class="form-title mt-3 mb-3">
  Interview Summary/Abstract
</div>
<div class="form-data" [formGroup]="abstractCurationForm">
  <div class="form-group">
    <label *ngIf="userType !== 'interviewer'" for="rawSummary" class="required">Raw, Unedited Summary</label>
    <label *ngIf="userType === 'interviewer'" for="rawSummary" class="required">Interview Summary/Abstract</label>
    <textarea id="rawSummary" class="w-100 form-textarea" formControlName="rawSummary" ></textarea>
    (600 Word Minimum)
  </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'  && userType !== 'copyediting'"  class="save-button" (click)="saveForm()">Save</button>
</div>
