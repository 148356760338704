<div class="form-title mt-3 mb-3">
    Interviewee Occupation and Parental Information
</div>
<div class="form-data" [formGroup]="parentalInformationForm">
    <div class="form-group">
        <label for="fatherName">Father's full name (given name and surname)</label>
        <input type="text" class="form-input" id="fatherName" formControlName="fatherName">
    </div>
    <div class="form-group">
        <label for="motherName">Mother's full name (given name and surname)</label>
        <input type="text" class="form-input" id="motherName" formControlName="motherName">
    </div>
    <div class="form-group">
        <label for="occupation1947" class="required">Interviewee occupation in 1947</label>
        <input type="text" class="form-input" id="occupation1947" formControlName="occupation1947">
    </div>
    <div class="form-group">
        <label for="career" class="required">Interviewee career</label>
        <input type="text" class="form-input" id="career" formControlName="career">
    </div>
    <div class="form-group">
        <label for="occupationToday" class="required">Interviewee occupation today</label>
        <input type="text" class="form-input" id="occupationToday"  formControlName="occupationToday">
    </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher' && userType !== 'copyediting'"  lass="save-button" (click)="saveForm()">Save</button>
</div>
