<div class="form-title mt-3 mb-5">
    Friends and Family Information
    <div class="mt-3" style="font-size:14px;margin:0 50px">
        Please provide at least 2 additional points of contact to the interviewee. Note that these two additional contacts are your interviewee’s, not yours (the interviewer’s). As the interviewer, you cannot serve as a friend or family contact, even if the interviewee happens to be a family member.
    </div>
</div>
<div class="form-data" [formGroup]="familyInformationForm">
    <div class="form-group">
        <label for="contact1FullName" class="required">Contact #1 Full Name</label>
        <input type="text" class="form-input" id="contact1FullName"  formControlName="contact1FullName">
    </div>
    <div class="form-group">
        <label for="contact1FirstName" class="required">Contact #1 First Name</label>
        <input type="text" class="form-input" id="contact1FirstName" formControlName="contact1FirstName">
    </div>
    <div class="form-group">
        <label for="contact1LastName" class="required">Contact #1 Last Name</label>
        <input type="text" class="form-input" id="contact1LastName"  formControlName="contact1LastName">
    </div>
    <div class="form-group">
        <label for="contact1Relation" class="required">Contact #1 Relation</label>
        <input type="text" class="form-input" id="contact1Relation" formControlName="contact1Relation">
    </div>
    <div class="form-group">
        <label for="contact1HouseNumber">Contact #1 House Number</label>
        <input type="text" class="form-input" id="contact1HouseNumber"  formControlName="contact1HouseNumber">
    </div>
    <div class="form-group">
        <label for="contact1Address">Contact #1 Address</label>
        <input type="text" class="form-input" id="contact1Address" formControlName="contact1Address">
      </div>
    <div class="form-group">
        <label for="contact1StreetName">Contact #1 Street Name</label>
        <input type="text" class="form-input" id="contact1StreetName" formControlName="contact1StreetName">
    </div>
    <div class="form-group">
        <label for="contact1City">Contact #1 City/Village</label>
        <input type="text" class="form-input" id="contact1City" formControlName="contact1City">
    </div>
    <div class="form-group">
        <label for="contact1District">Contact #1 District/Tehsil/Jilla</label>
        <input type="text" class="form-input" id="contact1District"  formControlName="contact1District">
    </div>
    <div class="form-group">
        <label for="contact1State">Contact #1 State/Province/Division</label>
        <input type="text" class="form-input" id="contact1State"  formControlName="contact1State">
    </div>
    <div class="form-group">
        <label for="contact1PostalCode">Contact #1 Zip or Postal Code</label>
        <input type="text" class="form-input" id="contact1PostalCode"  formControlName="contact1PostalCode">
    </div>
    <div class="form-group">
        <label for="contact1Country" class="required">Contact #1 Country</label>
        <input type="text" class="form-input" id="contact1Country"  formControlName="contact1Country">
    </div>
    <div class="form-group">
        <label for="contact1ResidenceDetails">Contact #1 Precise details about residence</label>
        <input type="text" class="form-input" id="contact1ResidenceDetails"  formControlName="contact1ResidenceDetails">
    </div>
    <div class="form-group">
        <label for="contact1Phone" class="required">Contact #1 Phone Number</label>
        <input type="text" class="form-input" id="contact1Phone"  formControlName="contact1Phone">
    </div>
    <div class="form-group">
        <label for="contact1Email" class="required">Contact #1 Email Address</label>
        <input type="text" class="form-input" id="contact1Email"  formControlName="contact1Email">
    </div>
    <div class="form-group">
        <label for="contact1Facebook">Contact #1 Facebook link</label>
        <input type="text" class="form-input" id="contact1Facebook"  formControlName="contact1Facebook">
    </div>
    <div class="form-group">
        <label for="contact1Twitter">Contact #1 Twitter handle</label>
        <input type="text" class="form-input" id="contact1Twitter"  formControlName="contact1Twitter">
    </div>
    <div class="form-group">
        <label for="contact1Instagram">Contact #1 Instagram handle</label>
        <input type="text" class="form-input" id="contact1Instagram"  formControlName="contact1Instagram">
    </div>
    <div class="form-group">
        <label for="contact1Linkedin">Contact #1 Linkedin handle</label>
        <input type="text" class="form-input" id="contact1Linkedin"  formControlName="contact1Linkedin">
    </div>
    <div class="form-group">
        <label for="contact1Tiktok">Contact #1 TikTok handle</label>
        <input type="text" class="form-input" id="contact1Tiktok"  formControlName="contact1Tiktok">
    </div>
    <div class="form-group">
        <label for="contact2FullName" class="required">Contact #2 Full Name</label>
        <input type="text" class="form-input" id="contact2FullName"  formControlName="contact2FullName">
    </div>
    <div class="form-group">
        <label for="contact2FirstName" class="required">Contact #2 First Name</label>
        <input type="text" class="form-input" id="contact2FirstName" formControlName="contact2FirstName">
    </div>
    <div class="form-group">
        <label for="contact2LastName" class="required">Contact #2 Last Name</label>
        <input type="text" class="form-input" id="contact2LastName"  formControlName="contact2LastName">
    </div>
    <div class="form-group">
        <label for="contact2Relation" class="required">Contact #2 Relation</label>
        <input type="text" class="form-input" id="contact2Relation" formControlName="contact2Relation">
    </div>
    <div class="form-group">
        <label for="contact2HouseNumber">Contact #2 House Number</label>
        <input type="text" class="form-input" id="contact2HouseNumber"  formControlName="contact2HouseNumber">
    </div>
    <div class="form-group">
        <label for="contact2FullAddress">Contact #2 Full Address</label>
        <input type="text" class="form-input" id="contact2FullAddress"  formControlName="contact2FullAddress">
    </div>
    <div class="form-group">
        <label for="contact2StreetName">Contact #2 Street Name</label>
        <input type="text" class="form-input" id="contact2StreetName" formControlName="contact2StreetName">
    </div>
    <div class="form-group">
        <label for="contact2City">Contact #2 City/Village</label>
        <input type="text" class="form-input" id="contact2City" formControlName="contact2City">
    </div>
    <div class="form-group">
        <label for="contact2District">Contact #2 District/Tehsil/Jilla</label>
        <input type="text" class="form-input" id="contact2District"  formControlName="contact2District">
    </div>
    <div class="form-group">
        <label for="contact2State">Contact #2 State/Province/Division</label>
        <input type="text" class="form-input" id="contact2State"  formControlName="contact2State">
    </div>
    <div class="form-group">
        <label for="contact2PostalCode">Contact #2 Zip or Postal Code</label>
        <input type="text" class="form-input" id="contact2PostalCode"  formControlName="contact2PostalCode">
    </div>
    <div class="form-group">
        <label for="contact2Country" class="required">Contact #2 Country</label>
        <input type="text" class="form-input" id="contact2Country"  formControlName="contact2Country">
    </div>
    <div class="form-group">
        <label for="contact2ResidenceDetails">Contact #2 Precise details about residence</label>
        <input type="text" class="form-input" id="contact2ResidenceDetails"  formControlName="contact2ResidenceDetails">
    </div>
    <div class="form-group">
        <label for="contact2Phone" class="required">Contact #2 Phone Number</label>
        <input type="text" class="form-input" id="contact2Phone"  formControlName="contact2Phone">
    </div>
    <div class="form-group">
        <label for="contact2Email" class="required">Contact #2 Email Address</label>
        <input type="text" class="form-input" id="contact2Email"  formControlName="contact2Email">
    </div>
    <div class="form-group">
        <label for="contact2Facebook">Contact #2 Facebook link</label>
        <input type="text" class="form-input" id="contact2Facebook"  formControlName="contact2Facebook">
    </div>
    <div class="form-group">
        <label for="contact2Twitter">Contact #2 Twitter handle</label>
        <input type="text" class="form-input" id="contact2Twitter"  formControlName="contact2Twitter">
    </div>
    <div class="form-group">
        <label for="contact2Instagram">Contact #2 Instagram handle</label>
        <input type="text" class="form-input" id="contact2Instagram"  formControlName="contact2Instagram">
    </div>
    <div class="form-group">
        <label for="contact2Linkedin">Contact #2 Linkedin handle</label>
        <input type="text" class="form-input" id="contact2Linkedin"  formControlName="contact2Linkedin">
    </div>
    <div class="form-group">
        <label for="contact2Tiktok">Contact #2 TikTok handle</label>
        <input type="text" class="form-input" id="contact2Tiktok"  formControlName="contact2Tiktok">
    </div>
    <div class="form-group">
        <label for="contact3FullName">Contact #3 Full Name</label>
        <input type="text" class="form-input" id="contact3FullName"  formControlName="contact3FullName">
    </div>
    <div class="form-group">
        <label for="contact3FirstName">Contact #3 First Name</label>
        <input type="text" class="form-input" id="contact3FirstName" formControlName="contact3FirstName">
    </div>
    <div class="form-group">
        <label for="contact3LastName">Contact #3 Last Name</label>
        <input type="text" class="form-input" id="contact3LastName"  formControlName="contact3LastName">
    </div>
    <div class="form-group">
        <label for="contact3Relation">Contact #3 Relation</label>
        <input type="text" class="form-input" id="contact3Relation" formControlName="contact3Relation">
    </div>
    <div class="form-group">
        <label for="contact3HouseNumber">Contact #3 House Number</label>
        <input type="text" class="form-input" id="contact3HouseNumber"  formControlName="contact3HouseNumber">
    </div>
    <div class="form-group">
        <label for="contact3FullAddress">Contact #3 Full Address</label>
        <input type="text" class="form-input" id="contact3FullAddress"  formControlName="contact3FullAddress">
    </div>
    <div class="form-group">
        <label for="contact3StreetName">Contact #3 Street Name</label>
        <input type="text" class="form-input" id="contact3StreetName" formControlName="contact3StreetName">
    </div>
    <div class="form-group">
        <label for="contact3City">Contact #3 City/Village</label>
        <input type="text" class="form-input" id="contact3City" formControlName="contact3City">
    </div>
    <div class="form-group">
        <label for="contact3District">Contact #3 District/Tehsil/Jilla</label>
        <input type="text" class="form-input" id="contact3District"  formControlName="contact3District">
    </div>
    <div class="form-group">
        <label for="contact3State">Contact #3 State/Province/Division</label>
        <input type="text" class="form-input" id="contact3State"  formControlName="contact3State">
    </div>
    <div class="form-group">
        <label for="contact3PostalCode">Contact #3 Zip or Postal Code</label>
        <input type="text" class="form-input" id="contact3PostalCode"  formControlName="contact3PostalCode">
    </div>
    <div class="form-group">
        <label for="contact3Country">Contact #3 Country</label>
        <input type="text" class="form-input" id="contact3Country"  formControlName="contact3Country">
    </div>
    <div class="form-group">
        <label for="contact3ResidenceDetails">Contact #3 Precise details about residence</label>
        <input type="text" class="form-input" id="contact3ResidenceDetails"  formControlName="contact3ResidenceDetails">
    </div>
    <div class="form-group">
        <label for="contact3Phone">Contact #3 Phone Number</label>
        <input type="text" class="form-input" id="contact3Phone"  formControlName="contact3Phone">
    </div>
    <div class="form-group">
        <label for="contact3Email">Contact #3 Email Address</label>
        <input type="text" class="form-input" id="contact3Email"  formControlName="contact3Email">
    </div>
    <div class="form-group">
        <label for="contact3Facebook">Contact #3 Facebook link</label>
        <input type="text" class="form-input" id="contact3Facebook"  formControlName="contact3Facebook">
    </div>
    <div class="form-group">
        <label for="contact3Twitter">Contact #3 Twitter handle</label>
        <input type="text" class="form-input" id="contact3Twitter"  formControlName="contact3Twitter">
    </div>
    <div class="form-group">
        <label for="contact3Instagram">Contact #3 Instagram handle</label>
        <input type="text" class="form-input" id="contact3Instagram"  formControlName="contact3Instagram">
    </div>
    <div class="form-group">
        <label for="contact3Linkedin">Contact #3 Linkedin handle</label>
        <input type="text" class="form-input" id="contact3Linkedin"  formControlName="contact3Linkedin">
    </div>
    <div class="form-group">
        <label for="contact3Tiktok">Contact #3 TikTok handle</label>
        <input type="text" class="form-input" id="contact3Tiktok"  formControlName="contact3Tiktok">
    </div>
    <div class="form-group">
        <label for="contact4FullName">Contact #4 Full Name</label>
        <input type="text" class="form-input" id="contact4FullName"  formControlName="contact4FullName">
    </div>
    <div class="form-group">
        <label for="contact4FirstName">Contact #4 First Name</label>
        <input type="text" class="form-input" id="contact4FirstName" formControlName="contact4FirstName">
    </div>
    <div class="form-group">
        <label for="contact4LastName">Contact #4 Last Name</label>
        <input type="text" class="form-input" id="contact4LastName"  formControlName="contact4LastName">
    </div>
    <div class="form-group">
        <label for="contact4Relation">Contact #4 Relation</label>
        <input type="text" class="form-input" id="contact4Relation" formControlName="contact4Relation">
    </div>
    <div class="form-group">
        <label for="contact4HouseNumber">Contact #4 House Number</label>
        <input type="text" class="form-input" id="contact4HouseNumber"  formControlName="contact4HouseNumber">
    </div>
    <div class="form-group">
        <label for="contact4FullAddress">Contact #4 Full Address</label>
        <input type="text" class="form-input" id="contact4FullAddress"  formControlName="contact4FullAddress">
    </div>
    <div class="form-group">
        <label for="contact4StreetName">Contact #4 Street Name</label>
        <input type="text" class="form-input" id="contact4StreetName" formControlName="contact4StreetName">
    </div>
    <div class="form-group">
        <label for="contact4City">Contact #4 City/Village</label>
        <input type="text" class="form-input" id="contact4City" formControlName="contact4City">
    </div>
    <div class="form-group">
        <label for="contact4District">Contact #4 District/Tehsil/Jilla</label>
        <input type="text" class="form-input" id="contact4District"  formControlName="contact4District">
    </div>
    <div class="form-group">
        <label for="contact4State">Contact #4 State/Province/Division</label>
        <input type="text" class="form-input" id="contact4State"  formControlName="contact4State">
    </div>
    <div class="form-group">
        <label for="contact4PostalCode">Contact #4 Zip or Postal Code</label>
        <input type="text" class="form-input" id="contact4PostalCode"  formControlName="contact4PostalCode">
    </div>
    <div class="form-group">
        <label for="contact4Country">Contact #4 Country</label>
        <input type="text" class="form-input" id="contact4Country"  formControlName="contact4Country">
    </div>
    <div class="form-group">
        <label for="contact4ResidenceDetails">Contact #4 Precise details about residence</label>
        <input type="text" class="form-input" id="contact4ResidenceDetails"  formControlName="contact4ResidenceDetails">
    </div>
    <div class="form-group">
        <label for="contact4Phone">Contact #4 Phone Number</label>
        <input type="text" class="form-input" id="contact4Phone"  formControlName="contact4Phone">
    </div>
    <div class="form-group">
        <label for="contact4Email">Contact #4 Email Address</label>
        <input type="text" class="form-input" id="contact4Email"  formControlName="contact4Email">
    </div>
    <div class="form-group">
        <label for="contact4Facebook">Contact #4 Facebook link</label>
        <input type="text" class="form-input" id="contact4Facebook"  formControlName="contact4Facebook">
    </div>
    <div class="form-group">
        <label for="contact4Twitter">Contact #4 Twitter handle</label>
        <input type="text" class="form-input" id="contact4Twitter"  formControlName="contact4Twitter">
    </div>
    <div class="form-group">
        <label for="contact4Instagram">Contact #4 Instagram handle</label>
        <input type="text" class="form-input" id="contact4Instagram"  formControlName="contact4Instagram">
    </div>
    <div class="form-group">
        <label for="contact4Linkedin">Contact #4 Linkedin handle</label>
        <input type="text" class="form-input" id="contact4Linkedin"  formControlName="contact4Linkedin">
    </div>
    <div class="form-group">
        <label for="contact4TikTok">Contact #4 TikTok handle</label>
        <input type="text" class="form-input" id="contact4TikTok"  formControlName="contact4TikTok">
    </div>
    <div class="form-group">
        <label for="contact5FullName">Contact #5 Full Name</label>
        <input type="text" class="form-input" id="contact5FullName"  formControlName="contact5FullName">
    </div>
    <div class="form-group">
        <label for="contact5FirstName">Contact #5 First Name</label>
        <input type="text" class="form-input" id="contact5FirstName" formControlName="contact5FirstName">
    </div>
    <div class="form-group">
        <label for="contact5LastName">Contact #5 Last Name</label>
        <input type="text" class="form-input" id="contact5LastName"  formControlName="contact5LastName">
    </div>
    <div class="form-group">
        <label for="contact5Relation">Contact #5 Relation</label>
        <input type="text" class="form-input" id="contact5Relation" formControlName="contact5Relation">
    </div>
    <div class="form-group">
        <label for="contact5HouseNumber">Contact #5 House Number</label>
        <input type="text" class="form-input" id="contact5HouseNumber"  formControlName="contact5HouseNumber">
    </div>
    <div class="form-group">
        <label for="contact5FullAddress">Contact #5 Full Address</label>
        <input type="text" class="form-input" id="contact5FullAddress"  formControlName="contact5FullAddress">
    </div>
    <div class="form-group">
        <label for="contact5StreetName">Contact #5 Street Name</label>
        <input type="text" class="form-input" id="contact5StreetName" formControlName="contact5StreetName">
    </div>
    <div class="form-group">
        <label for="contact5City">Contact #5 City/Village</label>
        <input type="text" class="form-input" id="contact5City" formControlName="contact5City">
    </div>
    <div class="form-group">
        <label for="contact5District">Contact #5 District/Tehsil/Jilla</label>
        <input type="text" class="form-input" id="contact5District"  formControlName="contact5District">
    </div>
    <div class="form-group">
        <label for="contact5State">Contact #5 State/Province/Division</label>
        <input type="text" class="form-input" id="contact5State"  formControlName="contact5State">
    </div>
    <div class="form-group">
        <label for="contact5PostalCode">Contact #5 Zip or Postal Code</label>
        <input type="text" class="form-input" id="contact5PostalCode"  formControlName="contact5PostalCode">
    </div>
    <div class="form-group">
        <label for="contact5Country">Contact #5 Country</label>
        <input type="text" class="form-input" id="contact5Country"  formControlName="contact5Country">
    </div>
    <div class="form-group">
        <label for="contact5ResidenceDetails">Contact #5 Precise details about residence</label>
        <input type="text" class="form-input" id="contact5ResidenceDetails"  formControlName="contact5ResidenceDetails">
    </div>
    <div class="form-group">
        <label for="contact5Phone">Contact #5 Phone Number</label>
        <input type="text" class="form-input" id="contact5Phone"  formControlName="contact5Phone">
    </div>
    <div class="form-group">
        <label for="contact5Email">Contact #5 Email Address</label>
        <input type="text" class="form-input" id="contact5Email"  formControlName="contact5Email">
    </div>
    <div class="form-group">
        <label for="contact5Facebook">Contact #5 Facebook link</label>
        <input type="text" class="form-input" id="contact5Facebook"  formControlName="contact5Facebook">
    </div>
    <div class="form-group">
        <label for="contact5Twitter">Contact #5 Twitter handle</label>
        <input type="text" class="form-input" id="contact5Twitter"  formControlName="contact5Twitter">
    </div>
    <div class="form-group">
        <label for="contact5Instagram">Contact #5 Instagram handle</label>
        <input type="text" class="form-input" id="contact5Instagram"  formControlName="contact5Instagram">
    </div>
    <div class="form-group">
        <label for="contact5Linkedin">Contact #5 Linkedin handle</label>
        <input type="text" class="form-input" id="contact5Linkedin"  formControlName="contact5Linkedin">
    </div>
    <div class="form-group">
        <label for="contact5Tiktok">Contact #5 TikTok handle</label>
        <input type="text" class="form-input" id="contact5Tiktok"  formControlName="contact5Tiktok">
    </div>
    <div class="form-group">
        <label for="contact6FullName">Contact #6 Full Name</label>
        <input type="text" class="form-input" id="contact6FullName"  formControlName="contact6FullName">
    </div>
    <div class="form-group">
        <label for="nextOfKin6">Next of kin #6</label>
        <input type="text" class="form-input" id="nextOfKin6"  formControlName="nextOfKin6">
    </div>
    <div class="form-group">
        <label for="kin6Phone">kin #6 phone</label>
        <input type="text" class="form-input" id="kin6Phone"  formControlName="kin6Phone">
    </div>
    <div class="form-group">
        <label for="kin6Email">kin #6 email</label>
        <input type="text" class="form-input" id="kin6Email"  formControlName="kin6Email">
    </div>
    <div class="form-group">
        <label for="contact7FullName">Contact #7 Full Name</label>
        <input type="text" class="form-input" id="contact7FullName"  formControlName="contact7FullName">
    </div>
    <div class="form-group">
        <label for="nextOfKin7">Next of kin #7</label>
        <input type="text" class="form-input" id="nextOfKin7"  formControlName="nextOfKin7">
    </div>
    <div class="form-group">
        <label for="kin7Phone">kin #7 phone</label>
        <input type="text" class="form-input" id="kin7Phone"  formControlName="kin7Phone">
    </div>
    <div class="form-group">
        <label for="kin7Email">kin #7 email</label>
        <input type="text" class="form-input" id="kin7Email"  formControlName="kin7Email">
    </div>
    <div class="form-group">
        <label for="contact8FullName">Contact #8 Full Name</label>
        <input type="text" class="form-input" id="contact8FullName"  formControlName="contact8FullName">
    </div>
    <div class="form-group">
        <label for="nextOfKin8">Next of kin #8</label>
        <input type="text" class="form-input" id="nextOfKin8"  formControlName="nextOfKin8">
    </div>
    <div class="form-group">
        <label for="kin7Phone">kin #8 phone</label>
        <input type="text" class="form-input" id="kin8Phone"  formControlName="kin8Phone">
    </div>
    <div class="form-group">
        <label for="kin7Email">kin #8 email</label>
        <input type="text" class="form-input" id="kin8Email"  formControlName="kin8Email">
    </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'  && userType !== 'copyediting'"   class="save-button" (click)="saveForm()">Save</button>
</div>
