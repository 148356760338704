<div class="form-title mt-3 mb-3">
    Interviewee Information
</div>
<div class="form-data" [formGroup]="intervieweeForm">
  <div class="form-group">
    <label for="intervieweeTitle">Interviewee title</label>
    <input type="text" class="form-input" id="intervieweeTitle" formControlName="title">
  </div>
  <div class="form-group">
    <label for="firstName" class="required">Interviewee first name</label>
    <input type="text" class="form-input" id="firstName" formControlName="firstName">
  </div>
  <div class="form-group">
    <label for="middleName" class="required">Interviewee middle name</label>
    <input type="text" class="form-input" id="middleName" formControlName="middleName">
  </div>
  <div class="form-group">
    <label for="lastName" class="required">Interviewee last name</label>
    <input type="text" class="form-input" id="lastName" formControlName="lastName">
  </div>
  <div class="form-group">
    <label for="fullName" class="required">Interviewee Full Name</label>
    <input type="text" class="form-input" id="fullName" formControlName="fullName">
  </div>
  <div class="form-group">
    <label for="intervieweeSuffix">Interviewee suffix</label>
    <input type="text" class="form-input" id="intervieweeSuffix" formControlName="suffix">
  </div>
  <div class="form-group">
    <label for="nameAtBirth">Name at birth if married</label>
    <input type="text" class="form-input" id="nameAtBirth" formControlName="nameAtBirth">
  </div>
  <div class="form-group">
    <label for="alternate">Alternate spellings of interviewee name and/or name at birth if married or changed name</label>
    <input type="text" class="form-input" id="alternate" formControlName="alternateSpellings">
  </div>
  <div class="form-group">
    <label for="dateOfBirth" class="required">Interviewee Date of Birth (mm/dd/yyyy)</label>
    <input type="text" class="form-input" id="dateOfBirth"  formControlName="dateOfBirth">
  </div>
  <div class="form-group">
      <label for="inexactDateOfBirth">Interviewee Date of Birth if Inexact (e.g. interviewee only knows the year). If applicable, please include information about calendars other than the Gregorian.</label>
      <input type="text" class="form-input" id="inexactDateOfBirth"  formControlName="inexactDateOfBirth">
  </div>
  <div>
      <label for="intervieweeAge" class="required">Age of interviewee at time of interview</label>
      <input type="text" class="form-input" id="intervieweeAge"  formControlName="intervieweeAge">
  </div>
  <div class="form-group">
      <label for="religion" class="required">Interviewee Religion</label>
      <input type="text" class="form-input" id="religion" formControlName="religion">
  </div>
  <div class="form-group">
      <label for="religiousConversion">Religious Conversion</label>
      <input type="text" class="form-input" id="religiousConversion"  formControlName="religiousConversion">
  </div>
  <div class="form-group">
      <label for="conversionNotes">Religious Conversion Notes</label>
      <textarea id="conversionNotes" class="w-100 form-textarea" formControlName="conversionNotes" (paste)="onPaste($event, 'conversionNotes')"></textarea>
  </div>
  <div class="form-group">
      <label for="intervieweeNativeLanguage">Native language of interviewee</label>
      <input type="text" class="form-input" id="intervieweeNativeLanguage"  formControlName="intervieweeNativeLanguage">
  </div>
  <div class="form-group">
      <label for="intervieweeRegionalLanguage">Regional dialect of interviewee</label>
      <input type="text" class="form-input" id="intervieweeRegionalLanguage"  formControlName="intervieweeRegionalLanguage">
  </div>
  <div class="form-group">
    <label for="gender" class="required">Gender of Interviewee</label>
    <input type="text" class="form-input" id="gender" formControlName="gender">
  </div>
  <div class="form-group">
      <label for="intervieweeRace">Race of interviewee (self-identified)</label>
      <input type="text" class="form-input" id="intervieweeRace"  formControlName="intervieweeRace">
  </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher' && userType !== 'copyediting'" class="save-button" (click)="saveForm()">Save</button>
</div>
