<div class="form-title mt-3 mb-3">
  Courtesy
</div>
<div class="form-data" [formGroup]="courtesyForm">
  <div class="form-group">
    <label class="required">Was the interviewer polite and professional?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="politeAndProfessional" value="Yes" id="yesPoliteAndProfessional" formControlName="politeAndProfessional">
      <label class="form-check-label" for="yesPoliteAndProfessional">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="politeAndProfessional" value="No" id="noPoliteAndProfessional" formControlName="politeAndProfessional">
      <label class="form-check-label" for="noPoliteAndProfessional">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Was the interviewer respectful?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="respectful" value="Yes" id="yesRespectful" formControlName="respectful">
      <label class="form-check-label" for="yesRespectful">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="respectful" value="No" id="noRespectful" formControlName="respectful">
      <label class="form-check-label" for="noRespectful">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Did the interviewer appear interested and engaged?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="interestedAndEngaged" value="Yes" id="yesInterestedAndEngaged" formControlName="interestedAndEngaged">
      <label class="form-check-label" for="yesInterestedAndEngaged">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="interestedAndEngaged" value="No" id="noInterestedAndEngaged" formControlName="interestedAndEngaged">
      <label class="form-check-label" for="noInterestedAndEngaged">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Did the interviewer let the interviewee speak without interruption?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="interruption" value="Yes" id="yesInterruption" formControlName="interruption">
      <label class="form-check-label" for="yesInterruption">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="interruption" value="No" id="noInterruption" formControlName="interruption">
      <label class="form-check-label" for="noInterruption">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Did the interviewer speak clearly?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="clearly" value="Yes" id="yesClearly" formControlName="clearly">
      <label class="form-check-label" for="yesClearly">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="clearly" value="No" id="noClearly" formControlName="clearly">
      <label class="form-check-label" for="noClearly">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Did the interviewer seem sensitive to the interviewee's needs?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="sensitive" value="Yes" id="yesSensitive" formControlName="sensitive">
      <label class="form-check-label" for="yesSensitive">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="sensitive" value="No" id="noSensitive" formControlName="sensitive">
      <label class="form-check-label" for="noSensitive">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label for="score" class="required">Overall Courtesy Score (1 being poor and 10 being excellent)</label>
    <input type="number" min="1" max="10" class="form-input" id="score" formControlName="score">
  </div>
  <div class="form-group">
    <label for="courtesyNotes" class="required">Notes and Comments</label>
    <textarea id="courtesyNotes" class="w-100 form-textarea" formControlName="courtesyNotes" (paste)="onPaste($event, 'courtesyNotes')"></textarea>
  </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'  && userType !== 'copyediting'"   class="save-button" (click)="saveForm()">Save</button>
</div>
