<div class="form-title mt-3 mb-5">
  Signed Release Form
  <div class="mt-3" style="text-align:left;font-size:14px;margin:0 50px">
    <span style="display:inline-block;width:20px"></span>Use Adobe Scan to scan both pages of the Release form, and merge them into a single pdf file.
    <br><br>
    <span style="display:inline-block;width:20px"></span>You can also take a photo of both Release form pages and submit them individually.
    <br><br>
    <span style="text-decoration:underline">File Naming Convention:</span>
    <br><br>
    <b>Release Form:</b> Firstname_Middlename_Lastname_mm-dd-yyyy_RELEASE_#of#
    <br>
    <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_RELEASE
    <div style="margin:15px 0">
      <span style="display:inline-block;width:20px"></span>or
    </div>
    <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_RELEASE_1of2
    <br>
    <span style="display:inline-block;width:20px"></span>Sikandar_Baptista_01-25-2017_RELEASE_2of2
  </div>
  <div id="div_video"> </div>
</div>
<app-file-uploader [fileType]="fileType" [interviewID]="interviewID"></app-file-uploader>
