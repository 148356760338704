<div class="form-title mt-3 mb-3">
    Narrator Information
</div>
<div class="form-data" [formGroup]="narratorForm">
    <div class="form-group">
      <label for="relationship">Relationship of Additional Narrators in the room to the interviewee</label>
      <textarea id="relationship" class="w-100 form-textarea" formControlName="relationship"></textarea>
    </div>
    <div class="form-group">
      <label for="additionalNarrators">Additional Narrators</label>
      <textarea id="additionalNarrators" class="w-100 form-textarea" formControlName="additionalNarrators" (paste)="onPaste($event, 'additionalNarrators')"></textarea>
    </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher' && userType !== 'copyediting'"  class="save-button" (click)="saveForm()">Save</button>
</div>
