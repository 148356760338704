<div class="form-title mt-3 mb-3">
  Sponsor Information
</div>
<div class="form-data" [formGroup]="sponsorInformation">
    <div class="form-group">
      <label for="sponsoredBy" class="required">Sponsored by</label>
      <input type="text" class="form-input" id="sponsoredBy" formControlName="sponsoredBy" required>
    </div>
    <div class="form-group">
      <label for="FacebookURL">Sponsor Facebook URL</label>
      <input type="text" class="form-input" id="facebookURL" formControlName="facebookURL">
    </div>
    <div class="form-group">
      <label for="TwitterHandle">Sponsor Twitter Handle</label>
      <input type="text" class="form-input" id="twitterHandle"  formControlName="twitterHandle">
    </div>
    <div class="form-group">
      <label for="InstagramHandle">Sponsor Instagram Handle</label>
      <input type="text" class="form-input" id="instagramHandle" formControlName="instagramHandle">
    </div>
    <div class="form-group">
      <label for="LinkedInProfile">Sponsor LinkedIn Profile</label>
      <input type="text" class="form-input" id="linkedInProfile" formControlName="linkedInProfile">
    </div>
    <div class="form-group">
      <label for="TikTokProfile">Sponsor TikTok Profile</label>
      <input type="text" class="form-input" id="tikTokProfile" formControlName="tikTokProfile">
    </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher' && userType !== 'copyediting'" class="save-button" (click)="saveForm()">Save</button>
</div>
