<div class="form-title mt-3 mb-3">
  Transcription Information
</div>
<div class="form-data" [formGroup]="transcriptionInformationForm">
  <div class="form-group">
    <label for="transcriberName">Transcriber name</label>
    <input type="text" class="form-input" id="transcriberName" formControlName="transcriberName">
  </div>
  <div class="form-group">
    <label for="transcriberContactInfo">Transcriber contact info</label>
    <input type="text" class="form-input" id="transcriberContactInfo" formControlName="transcriberContactInfo">
  </div>
  <div class="form-group">
    <label for="transcript">Transcript</label>
    <input type="text" class="form-input" id="transcript" formControlName="transcript">
  </div>
  <div class="form-group">
    <label for="transcriptEditorName">Transcript editor name</label>
    <input type="text" class="form-input" id="transcriptEditorName" formControlName="transcriptEditorName">
  </div>
  <div class="form-group">
    <label for="transcriptEditorContact">Transcript editor contact info</label>
    <input type="text" class="form-input" id="transcriptEditorContact" formControlName="transcriptEditorContact">
  </div>
  <div class="form-group">
    <label for="audioVideoEditorName" class="required">Audio/Video Editor Name</label>
    <input type="text" class="form-input" id="audioVideoEditorName" formControlName="audioVideoEditorName" required>
  </div>
  <div class="form-group">
      <label for="audioVideoEditorContactInfo" class="required">Audio/Video Editor Contact Info</label>
      <input type="text" class="form-input" id="audioVideoEditorContactInfo" formControlName="audioVideoEditorContactInfo" required>
  </div>
  <div class="form-group">
      <label for="captionerName" class="required">Captioner Name</label>
      <input type="text" class="form-input" id="captionerName" formControlName="captionerName" required>
  </div>
  <div class="form-group">
      <label for="captionerContactInfo" class="required">Captioner Contact Info</label>
      <input type="text" class="form-input" id="captionerContactInfo" formControlName="captionerContactInfo" required>
  </div>
</div>
<div class="mt-5 text-center">
    <button *ngIf="userType !==  'GIS Expert' && userType !== 'Researcher'  && userType !== 'copyediting'"  class="save-button" (click)="saveForm()">Save</button>
</div>
